/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { Input, Select, Collapse, Spin, Modal } from "antd";
import { LoadingOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useHookIDO } from "../StoreIdo";
import { NETWORK, CHAIN } from "../index.d";
import { fillNetwork, fillIconChain } from "../utilsIDO";

const ListIdoEnd = () => {
  const [state, actions]: any = useHookIDO();
  const [enableLoadMore, setEnableLoadMore] = useState(true);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [valueChain, setValueChain] = useState(CHAIN.ALL);

  const antIconLoad = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { Search } = Input;
  const onSearch = (value: string) => {
    setEnableLoadMore(false);
    actions.getListEnded({ category: 0, symbol: value });
  };
  const onChange = (value: any) => {
    if (value.target.value === "") {
      actions.getListEnded({ category: 0, symbol: "" });
      setEnableLoadMore(true);
    }
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const { Option } = Select;
  const params = {
    category: 0,
    lastTime:
      state.listEnded.length > 0
        ? state.listEnded[state.listEnded.length - 1].created
        : null,
  };

  useEffect(() => {
    actions.getListEnded({ category: 0, symbol: "" });
  }, []);

  const handleMore = () => {
    setLoadingMore(true);
    if (state.listEndedMore.length > 0) {
      params.lastTime =
        state.listEndedMore[state.listEndedMore.length - 1].created;
    }
    actions.getListEndedMore(params).then((data: any) => {
      setLoadingMore(false);
      if (data.length === 0) setEnableLoadMore(false);
    });
    // setLoadingMore(false);
  };

  const handleLoadMoreMobie = () => {
    if (state.listEndedMore.length > 0) {
      params.lastTime =
        state.listEndedMore[state.listEndedMore.length - 1].created;
    }
    actions.getListEndedMore(params).then((data: any) => {
      try {
        setLoadingSpin(true);
        setTimeout(() => {
          setLoadingSpin(false);
        }, 500);
      } catch (error) {
        setLoadingSpin(false);
      }
    });
  };

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const handleCallClickDetails = (network: any, symbol: any, isOldIDo: any) => {
    if (network === CHAIN.APTOS) {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // if (isMobile) {
      //   showConfirm();
      // } else {
      //   navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // }
    } else if (network === CHAIN.SOLANA) {
      navigate(`${"/Ido/IdoDetail"}/${network}?${symbol}`);
    } else if (isOldIDo) {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    } else {
      navigate(`${"/Ido/Details"}?${symbol}`);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;

  interface CollapseInterface {
    children: Element[];
    defaultActiveKey: string[];
    onChange: (key: string | string[]) => void;
  }

  const { Panel } = Collapse;

  const onChangeCollapse = (key: string | string[]) => {
    console.log(key);
  };

  const handleChangeChain = (value: any) => {
    setEnableLoadMore(true);
    // if (value === "all") {
    //   setEnableLoadMore(true);
    // }
    setValueChain(value);
  };

  const HeaderTopIdo = (props: any) => {
    const { ido } = props;
    return (
      <div className="content-header-panel">
        <div className="project-name-m">
          <button
            type="button"
            className="button-name"
            onClick={() =>
              handleCallClickDetails(ido.network, ido.symbol, ido.isOldIDo)
            }
          >
            {ido.name}
          </button>
        </div>
        <div className="token-name-m">
          <span
            className={
              Number(((ido.currentPrice / ido.pricePer) * 100).toFixed(2)) < 100
                ? "main-color-down"
                : Number(((ido.currentPrice / ido.pricePer) * 100).toFixed(2)) >
                  100
                  ? "main-color-up"
                  : ""
            }
          >
            {((ido.currentPrice / ido.pricePer) * 100).toFixed(2)}%
          </span>
        </div>
      </div>
    );
  };

  const ItemMobile = ({ ido }: any) => {
    return (
      <div className="content-joined-mobile">
        <div className="colum-content">
          <div className="item-content">
            <div className="text-l">Chain :</div>
            <div className="text-r">
              {" "}
              <span className="icon-symbol">{fillIconChain(ido.network)}</span>
              {fillNetwork(ido.network)}
            </div>
          </div>
          <div className="item-content">
            <div className="text-l">Token :</div>
            <div className="text-r">
              {" "}
              <span className="icon-symbol">
                {/* <img src="/images/imgido/icon-end.png" alt="" /> */}
                <img
                  style={{ width: "24px", height: "24px" }}
                  src={ido.logoUrl}
                  alt=""
                />
              </span>
              {ido.symbol}
            </div>
          </div>
          <div className="item-content">
            <div className="text-l">Swap rate:</div>
            <div className="text-r"> {ido.pricePer} USDT </div>
          </div>
          <div className="item-content">
            <div className="text-l">Curent price :</div>
            <div className="text-r">{ido.currentPrice} {ido.symbolUSD}</div>
          </div>
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <>
        <div className="main-list-end mobile">
          <div className="box-search-round">
            <div className="search-project">
              <Search
                placeholder="Search for project"
                onSearch={onSearch}
                onChange={onChange}
                style={{ width: 200 }}
              />
              <div className="icon-search">
                <img src="/images/imgido/search-normal.png" alt="" />
              </div>
              <div className="filter-project">
                <Select
                  defaultValue={CHAIN.ALL}
                  style={{ width: 125 }}
                  onChange={handleChangeChain}
                >
                  <Option value={CHAIN.ALL}>All chain</Option>
                  <Option value={CHAIN.OKC}> OKC </Option>
                  <Option value={CHAIN.APTOS}>Aptos </Option>
                  <Option value={CHAIN.SOLANA}>Solana </Option>
                  <Option value={CHAIN.TRX}>Tron </Option>
                  <Option value={CHAIN.ERC}> Erc </Option>
                  <Option value={CHAIN.BEP}> Bep </Option>
                  <Option value={CHAIN.POLY}> Poly </Option>
                  <Option value={CHAIN.AVAX}> Avax </Option>
                  <Option value={CHAIN.OP}> Optimism </Option>
                  <Option value={CHAIN.DOGE}> Doge Chain </Option>
                  <Option value={CHAIN.FANTOM}> Fantom </Option>
                </Select>
              </div>
            </div>

            <div
              className="collapse-mobile-ido"
              onTouchEnd={handleLoadMoreMobie}
            >
              <div className="table-mobile">
                <div className="title-rounds">Project Name</div>
                <div className="title-rounds">ATH Roi</div>
              </div>
              {/* @ts-ignore this lib is incompatible with react18  */}
              <Collapse
                defaultActiveKey={["index"]}
                onChange={onChangeCollapse}
              >
                {valueChain !== CHAIN.ALL
                  ? state.listEnded?.length > 0 &&
                  state.listEnded
                    .filter((item: any) => item.network === valueChain)
                    .map((ido: any, index: any) => (
                      // @ts-ignore this lib is incompatible with react18
                      <Panel
                        header={<HeaderTopIdo ido={ido} />}
                        key={index.toString()}
                        className="round-ido-m"
                      >
                        <ItemMobile ido={ido} index={index} />
                      </Panel>
                    ))
                  : state.listEnded?.length > 0 &&
                  state.listEnded.map((ido: any, index: any) => (
                    // @ts-ignore this lib is incompatible with react18
                    <Panel
                      header={<HeaderTopIdo ido={ido} />}
                      key={index.toString()}
                      className="round-ido-m"
                    >
                      <ItemMobile ido={ido} index={index} />
                    </Panel>
                  ))}

                {valueChain !== CHAIN.ALL
                  ? state.listEndedMore?.length > 0 &&
                  state.listEndedMore
                    .filter((item: any) => item.network === valueChain)
                    .map((ido: any, index: any) => (
                      // @ts-ignore this lib is incompatible with react18
                      <Panel
                        header={<HeaderTopIdo ido={ido} />}
                        key={index.toString()}
                        className="round-ido-m"
                      >
                        <ItemMobile ido={ido} index={index} />
                      </Panel>
                    ))
                  : state.listEndedMore?.length > 0 &&
                  state.listEndedMore.map((ido: any, index: any) => (
                    // @ts-ignore this lib is incompatible with react18
                    <Panel
                      header={<HeaderTopIdo ido={ido} />}
                      key={index.toString()}
                      className="round-ido-m"
                    >
                      <ItemMobile ido={ido} index={index} />
                    </Panel>
                  ))}
              </Collapse>
            </div>
          </div>
          {loadingSpin ? (
            <div className="loading-idoMore">
              <Spin indicator={antIcon} delay={1} />
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }

  let chain = localStorage.getItem("chain");

  return (
    <>
      <div className="main-list-end">
        <div className="box-search-round ido">
          <div className="search-project">
            <Search
              placeholder="Search for project"
              onSearch={onSearch}
              onChange={onChange}
              style={{ width: 200 }}
            />
            <div className="icon-search">
              <img src="/images/imgido/search-normal.png" alt="" />
            </div>
          </div>
          <div className="filter-project">
            <Select
              defaultValue={CHAIN.ALL}
              style={{ width: 125 }}
              onChange={handleChangeChain}
            >
              <Option value={CHAIN.ALL}>All chain</Option>
              <Option value={CHAIN.OKC}> OKC </Option>
              <Option value={CHAIN.APTOS}>Aptos </Option>
              <Option value={CHAIN.SOLANA}>Solana </Option>
              <Option value={CHAIN.TRX}>Tron </Option>
              <Option value={CHAIN.ERC}> Erc </Option>
              <Option value={CHAIN.BEP}> Bep </Option>
              <Option value={CHAIN.POLY}> Poly </Option>
              <Option value={CHAIN.AVAX}> Avax </Option>
              <Option value={CHAIN.OP}> Optimism </Option>
              <Option value={CHAIN.DOGE}> Doge Chain </Option>
              <Option value={CHAIN.FANTOM}> Fantom </Option>
            </Select>
          </div>
        </div>
        <div className="round-ending">
          <div className="columns">
            {valueChain !== CHAIN.ALL
              ? state.listEnded &&
              state.listEnded
                .filter((item: any) => item.network === valueChain)
                .map((item: any, i: any) => (
                  <>
                    <div className="colum w-33">
                      <div className="content-ending-round">
                        <div className="top-ending">
                          <div className="box-l">
                            <div className="icon-logo">
                              <img src={item.logoUrl} alt="" />
                            </div>
                            <div className="name">
                              <button
                                type="button"
                                className="button-name"
                                onClick={() =>
                                  handleCallClickDetails(
                                    item.network,
                                    item.symbol,
                                    item.isOldIDo
                                  )
                                }
                              >
                                {item.symbol}
                              </button>
                              <span
                                className={
                                  Number(
                                    (
                                      (item.currentPrice / item.pricePer) *
                                      100
                                    ).toFixed(2)
                                  ) < 100
                                    ? "main-color-down"
                                    : Number(
                                      (
                                        (item.currentPrice /
                                          item.pricePer) *
                                        100
                                      ).toFixed(2)
                                    ) > 100
                                      ? "main-color-up"
                                      : ""
                                }
                              >
                                {(
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <span className="title-name">{item.name}</span>
                            </div>
                          </div>
                          <div className="box-r">
                            <div className="box-img-price">
                              {Number(
                                (
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)
                              ) < 100 ? (
                                <img
                                  src="/images/imgido/price-downn.svg"
                                  alt=""
                                />
                              ) : Number(
                                (
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)
                              ) > 100 ? (
                                <img
                                  src="/images/imgido/price-upp.svg"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-ending">
                          <ul className="list-chain">
                            <li>
                              <div className="text-t">Chain</div>
                              <div className="text-b">
                                <span className="icon-symbol">
                                  {fillIconChain(item.network)}
                                </span>
                                {fillNetwork(item.network)}
                              </div>
                            </li>
                            <li>
                              <div className="text-t">Swap rate</div>
                              <div className="text-b">
                                {item.pricePer} USDT
                              </div>
                            </li>
                            <li>
                              <div className="text-t">ATH Price</div>
                              <div className="text-b">
                                {item.currentPrice} {item.symbolUSD}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : state.listEnded &&
              state.listEnded.map((item: any, i: any) => (
                <>
                  <div className="colum w-33">
                    <div
                      className="content-ending-round"
                      onClick={() =>
                        handleCallClickDetails(
                          item.network,
                          item.symbol,
                          item.isOldIDo
                        )
                      }
                    >
                      <div className="top-ending">
                        <div className="box-l">
                          <div className="icon-logo">
                            <img src={item.logoUrl} alt="" />
                          </div>
                          <div className="name">
                            <button
                              type="button"
                              className="button-name"
                              onClick={() =>
                                handleCallClickDetails(
                                  item.network,
                                  item.symbol,
                                  item.isOldIDo
                                )
                              }
                            >
                              {item.symbol}
                            </button>
                            <span
                              className={
                                Number(
                                  (
                                    (item.currentPrice / item.pricePer) *
                                    100
                                  ).toFixed(2)
                                ) < 100
                                  ? "main-color-down"
                                  : Number(
                                    (
                                      (item.currentPrice / item.pricePer) *
                                      100
                                    ).toFixed(2)
                                  ) >= 100
                                    ? "main-color-up"
                                    : ""
                              }
                            >
                              {(
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="title-name">{item.name}</span>
                          </div>
                        </div>
                        <div className="box-r">
                          <div className="box-img-price">
                            {Number(
                              (
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)
                            ) < 100 ? (
                              <img
                                src="/images/imgido/price-downn.svg"
                                alt=""
                              />
                            ) : Number(
                              (
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)
                            ) > 100 ? (
                              <img
                                src="/images/imgido/price-upp.svg"
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-ending">
                        <ul className="list-chain">
                          <li>
                            <div className="text-t">Chain</div>
                            <div className="text-b">
                              <span className="icon-symbol">
                                {fillIconChain(item.network)}
                              </span>
                              {fillNetwork(item.network)}
                            </div>
                          </li>
                          <li>
                            <div className="text-t">Swap rate</div>
                            <div className="text-b">{item.pricePer} USDT</div>
                          </li>
                          <li>
                            <div className="text-t">ATH Price</div>
                            <div className="text-b">
                              {item.currentPrice} {item.symbolUSD}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {loadingMore ? (
              <div className="nft-loading">
                <Spin indicator={antIconLoad} />
              </div>
            ) : valueChain !== CHAIN.ALL ? (
              state.listEndedMore &&
              state.listEndedMore
                .filter((item: any) => item.network === valueChain)
                .map((item: any, i: any) => (
                  <>
                    <div className="colum w-33">
                      <div
                        className="content-ending-round"
                        onClick={() =>
                          handleCallClickDetails(
                            item.network,
                            item.symbol,
                            item.isOldIDo
                          )
                        }
                      >
                        <div className="top-ending">
                          <div className="box-l">
                            <div className="icon-logo">
                              <img src={item.logoUrl} alt="" />
                            </div>
                            <div className="name">
                              <button
                                type="button"
                                className="button-name"
                                onClick={() =>
                                  handleCallClickDetails(
                                    item.network,
                                    item.symbol,
                                    item.isOldIDo
                                  )
                                }
                              >
                                {item.symbol}
                              </button>
                              <span
                                className={
                                  Number(
                                    (
                                      (item.currentPrice / item.pricePer) *
                                      100
                                    ).toFixed(2)
                                  ) < 100
                                    ? "main-color-down"
                                    : Number(
                                      (
                                        (item.currentPrice / item.pricePer) *
                                        100
                                      ).toFixed(2)
                                    ) > 100
                                      ? "main-color-up"
                                      : ""
                                }
                              >
                                {(
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)}
                                %
                              </span>
                              <span className="title-name">{item.name}</span>
                            </div>
                          </div>
                          <div className="box-r">
                            <div className="box-img-price">
                              {Number(
                                (
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)
                              ) < 100 ? (
                                <img
                                  src="/images/imgido/price-downn.svg"
                                  alt=""
                                />
                              ) : Number(
                                (
                                  (item.currentPrice / item.pricePer) *
                                  100
                                ).toFixed(2)
                              ) > 100 ? (
                                <img
                                  src="/images/imgido/price-upp.svg"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-ending">
                          <ul className="list-chain">
                            <li>
                              <div className="text-t">Chain</div>
                              <div className="text-b">
                                <span className="icon-symbol">
                                  {fillIconChain(item.network)}
                                </span>
                                {fillNetwork(item.network)}
                              </div>
                            </li>
                            <li>
                              <div className="text-t">Swap rate</div>
                              <div className="text-b">{item.pricePer} USDT</div>
                            </li>
                            <li>
                              <div className="text-t">ATH Price</div>
                              <div className="text-b">
                                {item.currentPrice} {item.symbolUSD}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ))
            ) : (
              state.listEndedMore &&
              state.listEndedMore.map((item: any, i: any) => (
                <>
                  <div className="colum w-33">
                    <div
                      className="content-ending-round"
                      onClick={() =>
                        handleCallClickDetails(
                          item.network,
                          item.symbol,
                          item.isOldIDo
                        )
                      }
                    >
                      <div className="top-ending">
                        <div className="box-l">
                          <div className="icon-logo">
                            <img src={item.logoUrl} alt="" />
                          </div>
                          <div className="name">
                            <button
                              type="button"
                              className="button-name"
                              onClick={() =>
                                handleCallClickDetails(
                                  item.network,
                                  item.symbol,
                                  item.isOldIDo
                                )
                              }
                            >
                              {item.symbol}
                            </button>
                            <span
                              className={
                                Number(
                                  (
                                    (item.currentPrice / item.pricePer) *
                                    100
                                  ).toFixed(2)
                                ) < 100
                                  ? "main-color-down"
                                  : Number(
                                    (
                                      (item.currentPrice / item.pricePer) *
                                      100
                                    ).toFixed(2)
                                  ) > 100
                                    ? "main-color-up"
                                    : ""
                              }
                            >
                              {(
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)}
                              %
                            </span>
                            <span className="title-name">{item.name}</span>
                          </div>
                        </div>
                        <div className="box-r">
                          <div className="box-img-price">
                            {Number(
                              (
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)
                            ) < 100 ? (
                              <img
                                src="/images/imgido/price-downn.svg"
                                alt=""
                              />
                            ) : Number(
                              (
                                (item.currentPrice / item.pricePer) *
                                100
                              ).toFixed(2)
                            ) > 100 ? (
                              <img src="/images/imgido/price-upp.svg" alt="" />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-ending">
                        <ul className="list-chain">
                          <li>
                            <div className="text-t">Chain</div>
                            <div className="text-b">
                              <span className="icon-symbol">
                                {fillIconChain(item.network)}
                              </span>
                              {fillNetwork(item.network)}
                            </div>
                          </li>
                          <li>
                            <div className="text-t">Swap rate</div>
                            <div className="text-b">{item.pricePer} USDT</div>
                          </li>
                          <li>
                            <div className="text-t">ATH Price</div>
                            <div className="text-b">
                              {item.currentPrice} {item.symbolUSD}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ))
            )}
          </div>
        </div>
        {enableLoadMore && state.listEnded.length !== 0 && !loadingMore ? (
          <button
            className="btn-loadmore"
            style={{ marginTop: "20px" }}
            onClick={handleMore}
          >
            More
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ListIdoEnd;
