/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return, no-param-reassign */
import axios from "axios";
import { API_URL } from "../../constants/index";
import {
  createStore,
  createHook,
  createContainer,
  createSubscriber,
} from "react-sweet-state";

const headersConfig = {
  "Content-Type": "application/json",
};

const StoreIDO = createStore({
  initialState: {
    owner: null,
    listUpComingCalender: [],
    listUpComing: [],
    listActive: [],
    listEnded: [],
    listEndedMore: [],
    listJoined: [],
    listResearch: {},
    listJoinedMore: [],
    objDetailIDO: {},
    listRefInfo: {},
    listRefJoined: {},
    listRefTop: [],
    claimIdoId: -1,
    totalVL: {},
    isHideRefundClaiming: false,
    isSubmit: false,
    isShowDisClaimer: true,
    totalCount: 0,
    listSignref: {}

  },
  actions: {
    getCalenderUpComing:
      (m, y) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpadv2/upcoming_calendar`, {
                month: m,
                year: y,
              })
              .then((res) => {
                setState({ listUpComingCalender: res.data.data || [] });
              });
          });
        },

    getListUpComing:
      (m, y) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpadv2/upcoming`, { month: m, year: y })
              .then((res) => {
                setState({ totalCount: res.data.totalCount || 0 });
                setState({ listUpComing: res.data.data || [] });
              });
          });
        },
    getListActive:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/open_idos`, params).then((res) => {
              setState({ listActive: res.data.data || [] });
            });
          });
        },
    getListEnded:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/ended_idos`, params).then((res) => {
              setState({ listEnded: res.data.data || [] });
              // console.log("res listEnded ", res);
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListEndedMore:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/ended_idos`, params).then((res) => {
              setState({
                listEndedMore:
                  getState().listEndedMore.concat(res.data.data) || [],
              });

              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListJoined:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            // axios
            //   .post(
            //     `https://api.bscstation.org/api/launchpad/joined_idos`,
            //     params
            //   )
            //   .then((res) => {
            axios.post(`${API_URL}/launchpad/joined_idos`, params).then((res) => {
              setState({ listJoined: res.data.data || [] });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListResearch:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpadv2/research_project`, params)
              .then((res) => {
                setState({ listResearch: res.data.data || {} });
                // console.log('data', getState().listResearch)
                const {
                  data: { data },
                } = res;
                resolve(data);
              });
          });
        },
    getListJoinedMore:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/joined_idos`, params).then((res) => {
              setState({
                listJoinedMore:
                  getState().listJoinedMore.concat(res.data.data) || [],
              });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getOwner:
      ({ account }) =>
        async ({ setState }) => {
          try {
            const res = await axios.post(`${API_URL}/Ido/owner_address`, {
              ownerAddress: account,
            });
            const {
              data: { data },
            } = res;
            setState({ owner: data });
          } catch (err) {
            // TODO
          }
        },
    // detail IDO
    getDetailIDO:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/detail`, params).then((res) => {
              // axios
              //   .post(`https://api.bscstation.org/api/launchpad/detail`, params)
              //   .then((res) => {
              setState({ objDetailIDO: res.data.data || {} });
              // console.log('data', getState().listResearch)
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getDetailIDO1:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/detail`, params).then((res) => {
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getRefInfo:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/info`, params).then((res) => {
              setState({ listRefInfo: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    updateRefuned:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/launchpad/refunded`, params).then((res) => {
              resolve(res?.data);
            });
          });
        },
    getRefJoined:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/joined`, params).then((res) => {
              setState({ listRefInfo: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getRefTop:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/top_reward`, params).then((res) => {
              setState({ listRefTop: res.data.data || [] });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getListSignref:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/affiliate/signref`, params).then((res) => {
              setState({ listSignref: res.data.data || {} });
              const {
                data: { data },
              } = res;
              resolve(data);
            });
          });
        },
    getTVL:
      () =>
        ({ setState }) => {
          return new Promise((resolve, reject) => {
            axios(`${API_URL}/Market/api/get_tvl`).then((res) => {
              const { data } = res.data;
              setState({ totalVL: { data } }); // TODO
              resolve(data);
            });
          });
        },

    changeOpenModalClaim:
      (ido_id) =>
        ({ setState }) => {
          setState({ claimIdoId: ido_id || -1 });
        },
    changeRefundStatus:
      (status) =>
        ({ setState }) => {
          setState({ isHideRefundClaiming: status });
        },
    resetData:
      () =>
        ({ getState, setState }) => {
          setState({ listJoined: [] });
          setState({ listJoinedMore: [] });
        },
    resetDetail:
      () =>
        ({ getState, setState }) => {
          setState({ objDetailIDO: {} });
        },
    submitAllocation:
      (params) =>
        async ({ setState, getState }) => {
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpad/commit_fund_allocation`, {
                ...params,
              })
              .then((res) => {
                setState({ isSubmit: res.data.succeeded });
                resolve(res);
              });
          });
        },
    updateShowDisClaimer:
      (val) =>
        ({ setState }) => {
          setState({ isShowDisClaimer: val });
        },
    addDisclaimer:
      (valueConfirm) =>
        ({ getState, setState }) => {
          // console.log("statuis:", valueConfirm)
          return new Promise((resolve, reject) => {
            axios
              .post(`${API_URL}/launchpad/disclaimer`, valueConfirm, {
                headers: headersConfig,
              })
              .then((res) => {
                resolve(res.data);
              });
          });
        },
  },
  name: "IDO Store",
});

export const useHookIDO = createHook(StoreIDO);
export const Container = createContainer(StoreIDO, {
  onInit:
    () =>
      ({ setState }, props) => {
        setState({ ...props });
      },
});
export const Subscriber = createSubscriber(StoreIDO);
