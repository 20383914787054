/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import React, { useEffect, useState, memo } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import TopIdoDetails from "./TopIdoDetails";
import BottomIdoDetails from "./BottomIdoDetails";
import { useHookIDO } from "../StoreIdo";
import { MAPPING_CHAINID, CHAINID_CONVERT } from "../../../constants";
import switchNetworkChain from "../../../utils/walletChain";
import { useHookPrice } from "../../../components/common/Store";
import ErrorPage from "../../ErrorPage/index";
import ModalDisClaimer from "../Disclaimer";
import { useAccount, useChainId } from "wagmi";
import { chainHeader } from "utils";


const IdoDetailsOld = memo(() => {
  const [state, actions]: any = useHookIDO();
  const { address } = useAccount();
  const chainId = useChainId()
  const [state1, actions1]: any = useHookPrice();
  const [objDetail, setObjDetail]: any = useState();
  let navigate = useNavigate();

  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];

  let urlNew = location.pathname.split("/")[2];


  const params1 = {
    address: '',
    symbol: pathHash,
  };

  const getObjDetail = async () => {
    try {
      await actions.getDetailIDO1(params1).then((res: any) => {
        setObjDetail(res)
      })
        .catch((err: any) => {
          setObjDetail()
        })
    } catch (error) {

    }
  }
  useEffect(() => {
    getObjDetail()
    return () => {
      setObjDetail()
    };
  }, []);

  const params = {
    address: address,
    symbol: pathHash,
  };

  useEffect(() => {
    if (address) {
      actions.getDetailIDO(params);
    }

    return () => {
      actions.resetDetail();
    };
  }, [address]);

  useEffect(() => {
    document.title = "Launchpad - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
      );
  }, []);
  let curentChain = localStorage.getItem("chain");

  useEffect(() => {
    if (address !== undefined) {
      if (
        state.objDetailIDO &&
        state.objDetailIDO.network !== CHAINID_CONVERT[chainId]
      )
        switchNetworkChain(
          MAPPING_CHAINID[state.objDetailIDO.network],
          state.objDetailIDO.network
        );
    }

    return () => {
      // TODO
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.objDetailIDO, address, chainId, state1.chain, curentChain]);

  useEffect(() => {
    // console.log("isOldIDo: ", state.objDetailIDO?.isOldIDo);
    if (state.objDetailIDO?.isOldIDo !== undefined) {
      if (state.objDetailIDO?.isOldIDo === false) {
        // console.log("222: ");
        // navigate("/");
        navigate(`/Ido/Details?${state.objDetailIDO.symbol}`);
      }
    }
  }, [state.objDetailIDO?.isOldIDo]);

  return (
    <>
      {state.objDetailIDO.network !== chainHeader(chainId) &&
        state.objDetailIDO.network !== undefined ? (
        //
        <ErrorPage />
      ) : (
        <>
          <div className="main-body">
            <div className="full-width">
              <div className="container">
                <div className="main-content-details">
                  <div className="breadcrum-swap">
                    <ul className="list-breadcrum">
                      <li>
                        <Link to={"/Ido"}>Project</Link>
                      </li>
                      <li>{">"}</li>
                      <li>IDO Pool</li>
                    </ul>
                    <TopIdoDetails dataTop={objDetail} />
                    <BottomIdoDetails dataBody={state.objDetailIDO} objDetail={objDetail} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {address ? (
            <ModalDisClaimer
              symbol={state.objDetailIDO.symbol}
              isShowDisClaim={state.objDetailIDO.showdisclaimer}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
});
export default IdoDetailsOld;
