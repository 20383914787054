/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
//@ts-ignore
import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import TopIdoDetails from "./TopIdoDetails";
import BottomIdoDetails from "./BottomIdoDetails";
import { useHookIDO } from "../StoreIdo";
import { useHookSol } from "../../Solana/Store-sol";


const IdoDetailsSOL = () => {
  const [state, actions]: any = useHookIDO();

  const [state2, actions2]: any = useHookSol();

  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];


  const params = {
    address: state2.accSOL,
    symbol: pathHash,
  };


  useEffect(() => {
    actions.getDetailIDO(params);
    return () => {
      actions.resetDetail();
    };
  }, [localStorage.getItem("accSOL"), state2.accSOL]);

  useEffect(() => {
    document.title = "Launchpad - BSCS (BSCS)";
    document
      .getElementsByTagName("meta")
      .item(1)
      ?.setAttribute(
        "content",
        "BSCS Launchpad is the innovative decentralized IDO platform across all main blockchain networks."
      );
  }, [state2.accSOL]);

  let curentChain = localStorage.getItem("chain");

  // useEffect(() => {
  //   if (
  //     state.objDetailIDO &&
  //     state.objDetailIDO.network !== CHAINID_CONVERT[chainId]
  //   )
  //     switchNetworkChain(
  //       MAPPING_CHAINID[state.objDetailIDO.network],
  //       state.objDetailIDO.network
  //     );

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.objDetailIDO, account, chainId, state1.chain]);

  return (
    <>
      <>
        <div className="main-body">
          <div className="full-width">
            <div className="container">
              <div className="main-content-details">
                <div className="breadcrum-swap">
                  <ul className="list-breadcrum">
                    <li>
                      <Link to={"/Ido"}>Project</Link>
                    </li>
                    <li>{">"}</li>
                    <li>IDO Pool</li>
                  </ul>
                  <TopIdoDetails dataTop={state.objDetailIDO} />
                  <BottomIdoDetails dataBody={state.objDetailIDO} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {state.objDetailIDO.network !== curentChain &&
        state.objDetailIDO.network !== undefined ? (
          <ErrorPage />
        ) : (
          <div className="main-body">
            <div className="full-width">
              <div className="container">
                <div className="main-content-details">
                  <div className="breadcrum-swap">
                    <ul className="list-breadcrum">
                      <li>
                        <Link to={"/Ido"}>Project</Link>
                      </li>
                      <li>{">"}</li>
                      <li>IDO Pool</li>
                    </ul>
                    <TopIdoDetails dataTop={state.objDetailIDO} />
                    <BottomIdoDetails dataBody={state.objDetailIDO} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </>
    </>
  );
};

export default IdoDetailsSOL;
