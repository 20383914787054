/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import "./style.css"

const ErrorPage = () => {
    return (
        <>
            <Wrapper>
                <div className="box-img-eror">
                    <img src="https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/00a9fa3e6fcc1f86aeae4bd63e2a072a5437c4d34f3a7f5ac5.png" alt="" />
                </div>
                <div className="text-eror">
                    <div className="title">
                        This Network's Settings Are Not Supported. Please Choose a Different Network
                    </div>
                    <div className="desc">
                        An all-in-one Incubation Hub with a full stack Defi platform across all main blockchain networks
                    </div>
                    <div className="button-back">
                        <button type="button" className="back-to">
                            <Link to={"/"} >
                                Back to home
                            </Link>
                        </button>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    padding-top: 128px;
    padding-bottom: 70px;
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/4e97c178bd7d5c3a2ba5717c9a027c7969e164fcb0b38e5eda.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;
export default ErrorPage