/* eslint-disable import/prefer-default-export */
export enum NETWORK {
  BSC = "bep",
  Poly = "poly",
  Ethereum = "erc",
  Aptos = "apt",
  Avax = "avax",
  Op = "op",
  OKC = "okc",
  Doge = "doge",
  Fantom = "ftm",
  ARB = "arb",
  OPBNB = "opbnb",
  SOL = "sol",
  TRON = "tron",
  SUI = "sui",
  BASE = 'base',
  ZKEVM='zkevm'
}

export enum STATUS {
  PENDING = 0,
  COMING = 1,
  GOING = 2,
  OPEN = 3,
  CLOSE = 4,
}

export enum POOL_WEIGHT {
  BRONZE = 15000,
  SILVER = 30000,
  GOLD = 50000,
  PLATINUM = 150000,
  DIAMOND = 300000,
}

export enum CHAIN {
  ALL = "all",
  APTOS = "apt",
  TRX = "trx",
  SOLANA = "sol",
  BEP = "bep",
  POLY = "poly",
  ERC = "erc",
  AVAX = "avax",
  OP = "op",
  OKC = "okc",
  DOGE = "doge",
  FANTOM = "ftm",
  ARB = "arb",
  OPBNB = "opbnb",
  BASE = "base",
    ZKEVM='zkevm'
}
export enum WEIGHT_TIER {
  Iron = 1,
  Bronze = 15,
  Silver = 35,
  Gold = 60,
  Platinum = 190,
  Diamond = 390,
  SupDiamond = 750,
}

export const MAPPING_CHAINID: any = {
  bep: "0x38",
  erc: "0x1",
  poly: "0x89",
  ftom: "0xFA",
  avax: "0xa86a",
  op: "0xA",
  okc: "0x42",
  arb: "0xa4b1",
  opbnb: "0xcc",
  base: "0x2105",
zkevm:'0x343b'
};

export const CHAINID_CONVERT: any = {
  56: "bep",
  1: "erc",
  138: "poly",
  250: "ftom",
  43114: "avax",
  10: "op",
  66: "okc",
  42161: "arb",
  204: "opbnb",
  8453: "base",
  13371:'zkevm'
};
