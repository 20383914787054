/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */
import React, { memo, useCallback, useEffect, useState } from "react";
import { Modal, Button, Checkbox, Select, Space } from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useHookIDO } from "./StoreIdo";
import dataCountry from "./dataCountry";
import { useAccount } from "wagmi";


const ModalDisClaimer = (props: any) => {
  const dataDisClaimer = props;
  const { address } = useAccount();
  let account = address
  const { Option } = Select;
  const { isShowDisClaim, symbol } = props;



  const [acceptConfirm, setAcceptConfirm] = useState(false);
  const [isShowModal, setShowModal] = useState(isShowDisClaim || false);
  const [listCondition, setListCondition] = useState<string[]>([]);
  const [state, actions]: any = useHookIDO();

  const [valueConfirm, setValueConfirm] = useState({
    ownerAddress: account,
    symbol: symbol,
    countryId: 84,
    status: 0,
    ipAddress: ''
  });

  async function getIp() {
    let x = fetch(`https://www.cloudflare.com/cdn-cgi/trace`)
    let y = (await x).text().then((data: any) => {
      data = data.trim().split('\n').reduce(function (obj: any, pair: any) {
        pair = pair.split('=');
        return obj[pair[0]] = pair[1], obj;
      }, {});


      setValueConfirm({
        ...valueConfirm,
        symbol: dataDisClaimer.symbol,
        ipAddress: data.ip
      })
    })

  }



  useEffect(() => {
    getIp()
  }, [dataDisClaimer])



  useEffect(() => {

    if (state.isShowDisClaimer) {
      setShowModal(isShowDisClaim);
    }



    setValueConfirm({
      ...valueConfirm,
      symbol: dataDisClaimer.symbol,
      ownerAddress: account,
    });
  }, [dataDisClaimer, account, isShowModal]);

  function handleChange(value: any) {
    setValueConfirm({
      ...valueConfirm,
      countryId: value,
    });
  }

  let navigate = useNavigate();

  let accountAddress:any = account;
  if (accountAddress === undefined) {
    accountAddress = localStorage.getItem("currentAddress") || "";
  }
  const location = useLocation();
  const pathSymbol = location.search.split("?");
  const pathHash = pathSymbol[1];

  const params = {
    address: accountAddress,
    symbol: pathHash,
  };




  const handleOk = (type: any) => {
    if (type === "confirm") {
      if (acceptConfirm) {
        const newValueConfirm = { ...valueConfirm };
        newValueConfirm.status = 1;

        actions.addDisclaimer(newValueConfirm);
        actions.updateShowDisClaimer(false);
        actions.getDetailIDO(params);

        setShowModal(false)

        // setShowModal(false)

      }
    }
    else {
      const newValueConfirm = { ...valueConfirm };
      newValueConfirm.status = 0;

      actions.addDisclaimer(newValueConfirm);
      actions.updateShowDisClaimer(false);
    }
  };

  function onChange(e: any) {
    const checked = e.target.checked;
    const value = e.target.value;
    if (checked) {
      if (!listCondition.includes(value)) {
        listCondition.push(value);

      }
    } else {
      const index = listCondition.indexOf(value);
      listCondition.splice(index, 1);
    }
    setListCondition([...listCondition]);
  }

  useEffect(() => {
    if (listCondition.length === 3) {
      setAcceptConfirm(true);
    } else {
      setAcceptConfirm(false);
    }
  }, [listCondition]);


  const contentModal = (
    <>
      <div className="content-modal-claimer">
        <div className="title">
          By entering to this website and/or using our Services, you hereby
          agree, represent and warrant that:
        </div>
        <ul className="list-text-claimer">
          <li>
            You will be solely responsible to proceed at your own discretion.
          </li>
          <li>
            You are not using, and will not in the future use, a VPN to mask
            your physical location from a restricted location.
          </li>
          <li>
            You are responsible for ensuring compliance with the laws of your
            jurisdiction in connections with your use of the our Services.
          </li>
          <li>
            You understand that BSCS is not liable for your compliance or
            non-compliance with any such laws.
          </li>
        </ul>
        <div className="title">
          Please confirm that you are not a Prohibited Person.
        </div>
        <ul className="list-text-claimer">
          <li className="li-mid">
            ""Prohibited Person” shall mean any individual or legal entity that is (i) a national or resident of, or legal entity formed or incorporated within or subject to the laws of any United States embargoed or restricted country; (ii) solely with respect to the non-government sanctioned trading, mining, minting of digital assets or cryptocurrency, or support of the foregoing, a national or resident of, or legal entity formed or incorporated within, or subject to the laws of the People’s Republic of China; (iii) a national or resident of, or legal entity formed or incorporated within or subject to the laws of the Republic of Cuba, Democratic People’s Republic of North Korea, Venezuela, Islamic Republic of Iran, Libya, Republic of South Sudan, Republic of Sudan, Syrian Arab Republic, the Crimea, or any other jurisdiction in which it is prohibited from using the Website (the ”Prohibited Jurisdictions”); (iv) included on, or affiliated with any Person on, the United States Commerce Department’s Denied Persons List, Entities List, or Unverified List; the U.S. Department of the Treasury’s Specially Designated Nationals and Blocked Persons List, Specially Designated Narcotics Traffickers or Specially Designated Terrorists, or the Annex to Executive Order No. 13224; the Department of State’s Debarred List; or UN Sanctions; (v) a Person with whom business transactions, including exports and re-exports, are restricted by a United States Governmental Authority, including each item listed in the foregoing clauses (i), (ii), (iii), (iv) and (v) and any updates or revisions thereto and any newly published rules therefore; or (vi) a subject or target of any other economic sanctions administered or enforced by the United Nations, the European Union, the United States of America, or the United Kingdom."
          </li>
        </ul>
        <div className="content-checkbox">
          <ul className="list-checkbox">
            <li className="li-bot">
              <Checkbox value="condition-1" onChange={onChange}>
                I declare that I am NOT a resident of the prohibited territories
                or possessions as listed above.{" "}
              </Checkbox>
            </li>
            <li className="li-bot">
              <Checkbox value="condition-2" onChange={onChange}>
                I have read, Understood , and agree with the{" "}
                <span className="main-color-claimer">Privacy Policy</span> and
                the <span className="main-color-claimer">Term of Service</span>{" "}
              </Checkbox>
            </li>
            <li className="li-bot">
              <Checkbox value="condition-3" onChange={onChange}>
                I declare that, I am the resident of{" "}
              </Checkbox>

              <div className="box-select">
                <Select
                  defaultValue={valueConfirm.countryId}
                  style={{ width: 150 }}
                  onChange={handleChange}
                >
                  {dataCountry.map((item, index) => (
                    <Option value={item.phoneCode}>{item.value}</Option>
                  ))}
                </Select>
              </div>

            </li>
          </ul>
        </div>
        <div className="footer-modal">

          {/* <button
            className="btn-modal-claimer"
            type="button"
            onClick={() => handleOk("nerverMind")}
          >
            Nevermind
          </button> */}
          <button
            className={`btn-modal-claimer ${!acceptConfirm ? "disable" : ""}`}
            type="button"
            onClick={() => handleOk("confirm")}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );



  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
                Open Modal
            </Button> */}
      {/* @ts-ignore this lib is incompatible with react18  */}
      <Modal
        className="modal-disclaimer"
        destroyOnClose={true}
        title="Just A Sec!"
        footer={false}
        // visible={isShowDisClaim}
        visible={isShowModal}
      >
        {contentModal}
      </Modal>
    </>
  );
};
export default ModalDisClaimer;
