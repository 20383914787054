/* eslint-disable no-unreachable */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHookProjects } from "./Store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { isMobile } from "react-device-detect";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const ProjectRaise = () => {
  const [state, actions]: any = useHookProjects();
  const listIdoPriceHome = state.idoListPriceHome;

  let navigate = useNavigate();
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      className: "modal-not-sp",
      title: "Project not suport on Mobile",
      icon: <ExclamationCircleFilled />,
      content: "Please switch to another device.",
    });
  };

  const gotoIDODetail = (network: any, symbol: any) => {
    if (network === "apt") {
      navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // if (isMobile) {
      //   showConfirm();
      // } else {
      //   navigate(`${"/Ido/IdoDetails"}/${network}?${symbol}`);
      // }
    } else {
      navigate(`${"/Ido/IdoDetails"}?${symbol}`);
    }
  };

  let arr1EndIdo = listIdoPriceHome.slice(0, 8);
  let arr2EndIdo = listIdoPriceHome.slice(8, 16);
  let arr3EndIdo = listIdoPriceHome.slice(16);

  const Item = (item: any) => {
    return (
      <div
        className="colum w-10"
        onClick={() => gotoIDODetail(item.network, item.symbol)}
      >
        <div className="box-ido-raise">
          <div className="icon-raise">
            <img src={item.logoUrl} alt="" />
          </div>
          <div className="name-raise">{item.name}</div>
          <div className="number-raise">
            <div
              className={
                Number(((item.currentPrice / item.idoPrice) * 100).toFixed(1)) <
                100
                  ? "down-price"
                  : Number(
                      ((item.currentPrice / item.idoPrice) * 100).toFixed(1)
                    ) > 100
                  ? "up-price"
                  : ""
              }
            >
              {((item.currentPrice / item.idoPrice) * 100).toFixed(1)}%
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-setion">
        <div className="main-title">
          <p className="desc">
            Helping the most successful projects raise funding
          </p>
        </div>
        <div className="table-project-raise">
          <div className="columns">
            <Marquee pauseOnHover={true} speed={20} className="marquee-custom">
              {arr1EndIdo.length !== 0 ? (
                arr1EndIdo.map((item: any, index: any) => <>{Item(item)}</>)
              ) : (
                <div className="colum w-10">{/* <Empty /> */}</div>
              )}
            </Marquee>
            <Marquee
              pauseOnHover={true}
              speed={20}
              className="marquee-custom"
              direction="right"
            >
              {arr2EndIdo.length !== 0 ? (
                arr2EndIdo.map((item: any, index: any) => <>{Item(item)}</>)
              ) : (
                <div className="colum w-10">{/* <Empty /> */}</div>
              )}
            </Marquee>
            <Marquee pauseOnHover={true} speed={20} className="marquee-custom">
              {arr3EndIdo.length !== 0 ? (
                arr3EndIdo.map((item: any, index: any) => <>{Item(item)}</>)
              ) : (
                <div className="colum w-10">{/* <Empty /> */}</div>
              )}
            </Marquee>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectRaise;
